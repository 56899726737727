import { lazy } from "react";
import { TransactionOutlined } from "@ant-design/icons";
import Authenticate from "@components/basic-layout/BasicLayoutAuthenticate";
const Order = lazy(() =>
  import(/* webpackChunkName: "commission" */ "@views/Commission/Order")
);
const Designer = lazy(() =>
  import(/* webpackChunkName: "commission" */ "@views/Commission/Designer")
);
const CommodityCommission = lazy(() =>
  import(
    /* webpackChunkName: "commission" */ "@views/Commission/CommodityCommission"
  )
);

const commodityCommissionRoutes = [
  {
    key: "commission",
    nav: {
      title: "商品返佣",
      icon: <TransactionOutlined />
    },
    children: [
      {
        path: "/commission/order",
        permission: "commodity_brands-list",
        nav: {
          title: "订单管理"
        },
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "商品返佣"
              },
              {
                name: "订单管理"
              }
            ]}
            title="订单管理"
            permission="commodity_brands-list"
            element={<Order />}
          />
        )
      },
      {
        path: "/commission/designer",
        permission: "commodity_brands-list",
        nav: {
          title: "设计师管理"
        },
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "商品返佣"
              },
              {
                name: "设计师管理"
              }
            ]}
            title="设计师管理"
            permission="commodity_brands-list"
            element={<Designer />}
          />
        )
      },
      {
        path: "/commission/commodity",
        nav: {
          title: "商品返佣管理"
        },
        permission: "commodity-commission-list",
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "商品库"
              },
              {
                name: "商品返佣管理"
              }
            ]}
            title="商品返佣管理"
            permission="commodity-commission-list"
            element={<CommodityCommission />}
          />
        )
      }
    ]
  }
];

export default commodityCommissionRoutes;
