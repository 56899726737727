import { Scrollbars } from 'react-custom-scrollbars';

function BaiscLayoutScroll(props) {
  return <Scrollbars autoHide>
    <div style={{padding: '12px',height: '100%'}}>
      {props.children}
    </div>
  </Scrollbars>
}

export default BaiscLayoutScroll;
