import request from "@request/axios.js";
import API from "@request/api.js";

const smsService = {
  smsRegisterCode: ({ phone }) => {
    return request.post(API.smsRegisterCode, { phone });
  },
  smsLogin: ({ key, code, phone }) => {
    return request.post(API.bindPhone, {
      key,
      code,
      phone
    },{
      withCredentials: true
    });
  }
};

export default smsService;
