import { lazy } from "react";
import Authenticate from "@components/basic-layout/BasicLayoutAuthenticate";
import { TeamOutlined } from "@ant-design/icons";
const Team = lazy(() =>
  import(/* webpackChunkName: "team" */ "@views/Team/Team")
);
const teamRoutes = [
  {
    key: "user",
    nav: {
      title: "团队管理",
      icon: <TeamOutlined />
    },
    children: [
      {
        path: "/team/list",
        permission: "teams-list",
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "团队管理"
              }
            ]}
            title="团队管理"
            permission="teams-list"
            element={<Team />}
          />
        ),
        nav: {
          title: "团队管理"
        }
      }
    ]
  }
];

export default teamRoutes;
