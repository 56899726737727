import { lazy } from "react";
import Authenticate from "@components/basic-layout/BasicLayoutAuthenticate";
import { MailOutlined } from "@ant-design/icons";
const User = lazy(() =>
  import(/* webpackChunkName: "user" */ "@views/User/index")
);
const userRoutes = [
  {
    key: "user",
    nav: {
      title: "用户中心",
      icon: <MailOutlined />
    },
    children: [
      {
        path: "/user/list",
        permission: "users-list",
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "用户中心"
              }
            ]}
            title="用户列表"
            permission="users-list"
            element={<User />}
          />
        ),
        nav: {
          title: "用户列表"
        }
      }
    ]
  }
];

export default userRoutes;
