import PropTypes from 'prop-types';
import { Menu, Dropdown, Avatar } from 'antd';

BasicLayoutHeaderAvatar.propTypes = {
  userInfo: PropTypes.shape({
    avatar_url: PropTypes.string,
    nickname: PropTypes.string
  }),
  logout: PropTypes.func.isRequired,
};

const menuItems = [
  {
    label: '退出登录', key: 'logout'
  }
];
const menuItemClick = ({key}, props) => {
  key === 'logout' && props.logout()
  return;
}

export const userOverlayMenu = (props) => (
  <Menu items={menuItems} onClick={(item)=>menuItemClick(item, props)}>
  </Menu>
);

export const getElementById = (id) => {
  return () => document.getElementById(id);
}

function BasicLayoutHeaderAvatar(props) {
  return (
    <div className="user-container" 
      id="header-userinfo-container">
      <Dropdown
        overlay={userOverlayMenu(props)} 
        arrow 
        placement="bottomRight" 
        getPopupContainer={getElementById('header-userinfo-container')}>
        <Avatar data-testid="user-avatar" className="user-avatar" src={props.userInfo?.avatar_url} />
      </Dropdown>
    </div>
  );
}

export default BasicLayoutHeaderAvatar;