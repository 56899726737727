import { useState, useEffect } from 'react';
import { Typography, Divider, Alert, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { Title, Paragraph } = Typography;

function Home() {
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const key = window.localStorage.getItem('warning_tips');
    if(!key) {
      setIsModalVisible(true);
    }
  },[]);

  const handleOk = () => {
    setIsModalVisible(false);
    window.localStorage.setItem('warning_tips', Date.now());
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const tips = "服务器使用者注意 ”防止素材资产泄露“ ，违规操作会触发报警提醒。";

  return (
    <>
      <Typography>
        <Title level={3}>斗西家计划管理平台</Title>
        <Paragraph>斗西家计划作为国内顶尖设计师创立的专为解决普通人装修困难的学习平台，让每一个装修的小白，都可以自学装修出自己最理想的家。</Paragraph>
        <Alert message={'关于权限及任何技术问题，请联系技术管理员「 华仔 」~~'} />
      </Typography>
      <Divider />

      <Modal
        destroyOnClose
        keyboard={false}
        maskClosable={false}
        icon={<ExclamationCircleOutlined />}
        title={"注意"}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="确认"
      >
        <p>{tips}</p>
      </Modal>
    </>
  );
}

export default Home;