const PREFIX = `${process.env.REACT_APP_HOST}`;
const ADMIN_PREFIX = `${PREFIX}/api/admin`;

const API = {
  smsRegisterCode: `${ADMIN_PREFIX}/sms/register-code`,
  bindPhone: `${ADMIN_PREFIX}/sms/login`,
  userInfo: `${ADMIN_PREFIX}/users/user-info`,
  userPermissions: `${ADMIN_PREFIX}/permissions/my`,
  permissions: `${ADMIN_PREFIX}/permissions`,
  roles: `${ADMIN_PREFIX}/roles`,
  rolesId: id => `${ADMIN_PREFIX}/roles/${id}`,
  managers: `${ADMIN_PREFIX}/administrators`,
  managersId: id => `${ADMIN_PREFIX}/administrators/${id}`,
  user: `${ADMIN_PREFIX}/users`,
  allUser: `${ADMIN_PREFIX}/all/users`,
  userId: id => `${ADMIN_PREFIX}/users/${id}`,

  ossToken: `${ADMIN_PREFIX}/file/alioss-token`,
  ossStore: `${ADMIN_PREFIX}/file/alioss-store`,

  //商品
  commodities: `${ADMIN_PREFIX}/commodity`,
  commodity: id => `${ADMIN_PREFIX}/commodity/${id}`,
  commodityAttrsByCatId: id => `${ADMIN_PREFIX}/commodity/${id}/attrs`,
  commodityBrands: `${ADMIN_PREFIX}/commodity-brand`,
  commodityAttr: id => `${ADMIN_PREFIX}/commodity-attr/${id}`,
  commodityAttrs: `${ADMIN_PREFIX}/commodity-attr`,
  commodityCats: `${ADMIN_PREFIX}/commodity-cat`,
  commodityCat: id => `${ADMIN_PREFIX}/commodity-cat/${id}`,
  commodityBrand: id => `${ADMIN_PREFIX}/commodity-brand/${id}`,
  commodityAddCat: `${ADMIN_PREFIX}/commodity/add/cat`,
  commodityMoveCat: `${ADMIN_PREFIX}/commodity/move/cat`,
  commodityAgents: `${ADMIN_PREFIX}/commodity-agent`,
  commodityAgentMulti: `${ADMIN_PREFIX}/commodity-agent-multi`,
  commodityAgent: id => `${ADMIN_PREFIX}/commodity-agent/${id}`,
  commodityCommission: `${ADMIN_PREFIX}/commodity-commission`,
  commodityCommissionId: id => `${ADMIN_PREFIX}/commodity-commission/${id}`,
  commodityColor: `${ADMIN_PREFIX}/commodity-color`,
  commodityColorId: id => `${ADMIN_PREFIX}/commodity-color/${id}`,
  commodityMaterial: `${ADMIN_PREFIX}/commodity-material`,
  commodityMaterialId: id => `${ADMIN_PREFIX}/commodity-material/${id}`,
  // 素材
  commoditySkuCats: `${ADMIN_PREFIX}/commodity-sku-cat`,
  commoditySkuCatsByKjlIndex: index =>
    `${ADMIN_PREFIX}/commodity-sku-cat/index/${index}`,
  commoditySkuCat: id => `${ADMIN_PREFIX}/commodity-sku-cat/${id}`,
  commodityCatSkus: catId =>
    `${ADMIN_PREFIX}/commodity-sku-cat-relation/${catId}`,
  commoditySkus: `${ADMIN_PREFIX}/commodity-sku`,
  commoditySkuMulti: `${ADMIN_PREFIX}/commodity-sku-multi`,
  commoditySku: id => `${ADMIN_PREFIX}/commodity-sku/${id}`,
  commoditySkuDownload: id => `${ADMIN_PREFIX}/commodity-sku/${id}/download`,
  commoditySkuMove: `${ADMIN_PREFIX}/commodity-sku/move`,
  commoditySkuSync: `${ADMIN_PREFIX}/commodity-sku/sync`,
  commoditySkuAttrs: id => `${ADMIN_PREFIX}/commodity-sku/${id}/attrs`,
  commoditySkuCreateCommodityMulti: `${ADMIN_PREFIX}/commodity-sku/create-commodity-multi`,
  commoditySkusByCid: id => `${ADMIN_PREFIX}/commodity/${id}/skus`,
  commodityCatAttrs: catId => `${ADMIN_PREFIX}/commodity-cat/${catId}/attrs`,
  commodityCatAttr: catId => `${ADMIN_PREFIX}/commodity-cat/${catId}/attrs`,
  commodityCatAttrId: id => `${ADMIN_PREFIX}/commodity-cat-attr/${id}`,
  commodityProductSkus: id => `${ADMIN_PREFIX}/commodity/${id}/product-skus`,
  commodityAttrsId: commodityId =>
    `${ADMIN_PREFIX}/commodity/${commodityId}/attrs`,
  commoditySkuBind: id => `${ADMIN_PREFIX}/commodity-sku/${id}/bind`,
  commodityJson: `${ADMIN_PREFIX}/commodity-json`,
  commodityJsonAll: `${ADMIN_PREFIX}/commodity-json/all`,
  commoditySkuUnbind: id => `${ADMIN_PREFIX}/commodity-sku/${id}/unbind`,
  commodityUnBindAllSku: id => `${ADMIN_PREFIX}/commodity/${id}/unbind-all-sku`,

  //风格
  commodityStyleCats: `${ADMIN_PREFIX}/commodity-style-cat`,
  commodityStyleCatId: catId => `${ADMIN_PREFIX}/commodity-style-cat/${catId}`,
  commodityStyles: `${ADMIN_PREFIX}/commodity-style`,
  commodityStyleId: id => `${ADMIN_PREFIX}/commodity-style/${id}`,
  commodityStyleMoveCat: `${ADMIN_PREFIX}/commodity-style/move/cat`,
  commodityStyleSpace: styleId =>
    `${ADMIN_PREFIX}/commodity-style/${styleId}/space`,
  commodityStyleSpaceId: (styleId, id) =>
    `${ADMIN_PREFIX}/commodity-style/${styleId}/space/${id}`,
  commodityStyleSpaceSort: (styleId, id) =>
    `${ADMIN_PREFIX}/commodity-style/${styleId}/space/${id}/sort`,
  commodityStyleSpaceSkuId: id => `${ADMIN_PREFIX}//commodity-space/sku/${id}`,
  commodityStyleSpaceSkuSort: id =>
    `${ADMIN_PREFIX}//commodity-space/sku/${id}/sort`,
  commodityStyleSpaceSku: (styleId, spaceId) =>
    `${ADMIN_PREFIX}/commodity-style/${styleId}/space/${spaceId}/sku`,
  commodityStyleSpaceSkuItem: (styleId, spaceId, skuId) =>
    `${ADMIN_PREFIX}/commodity-style/${styleId}/space/${spaceId}/sku/${skuId}`,
  commodityStyleSpaceSkuItemSort: (styleId, spaceId, skuId) =>
    `${ADMIN_PREFIX}/commodity-style/${styleId}/space/${spaceId}/sku/${skuId}/sort`,

  // 系列
  commoditySeries: `${ADMIN_PREFIX}/commodity-series`,
  commoditySeriesId: id => `${ADMIN_PREFIX}/commodity-series/${id}`,

  //speeduck
  feedbackSpeeduck: `${ADMIN_PREFIX}/su/speeduck/feedback`,
  feedbackModel: `${ADMIN_PREFIX}/su/model/feedback`,

  //su 版本
  suVersion: `${ADMIN_PREFIX}/su-versions`,
  suVersionId: id => `${ADMIN_PREFIX}/su-version/${id}`,
  //su 会员
  suUsers: `${ADMIN_PREFIX}/platform/platform/users`,
  suUserDownloadCount: `${ADMIN_PREFIX}/platform/platform/download-count`,
  suUserDownloadDate: `${ADMIN_PREFIX}/platform/platform/download-date`,
  suUserDownloadLog: `${ADMIN_PREFIX}/platform/platform/download-log`,

  liveComments: `${ADMIN_PREFIX}/live-comment`,

  //daylab
  daylabWorkFormats: `${ADMIN_PREFIX}/work-formats`,
  daylabWorkFormat: id => `${ADMIN_PREFIX}/work-format/${id}`,
  daylabWorkCats: `${ADMIN_PREFIX}/work-cat`,
  daylabWorkCat: id => `${ADMIN_PREFIX}/work-cat/${id}`,
  daylabWorkLocations: `${ADMIN_PREFIX}/work-locations`,
  daylabWorkLocation: id => `${ADMIN_PREFIX}/work-location/${id}`,
  daylabWorks: `${ADMIN_PREFIX}/works`,
  daylabWork: id => `${ADMIN_PREFIX}/work/${id}`,
  daylabAwards: `${ADMIN_PREFIX}/awards`,
  daylabAward: id => `${ADMIN_PREFIX}/award/${id}`,
  daylabTeamMembers: `${ADMIN_PREFIX}/team-members`,
  daylabTeamMember: id => `${ADMIN_PREFIX}/team-member/${id}`,

  //会员
  vipHistories: `${ADMIN_PREFIX}/vip/histories`,
  vipUser: `${ADMIN_PREFIX}/vip/user`,
  vipSkus: `${ADMIN_PREFIX}/vip/skus`,

  // 色卡
  colorCats: `${ADMIN_PREFIX}/color/cats`,
  colorCatId: catId => `${ADMIN_PREFIX}/color/cats/${catId}`,
  colors: `${ADMIN_PREFIX}/color/cards`,
  colorImport: `${ADMIN_PREFIX}/color/cards-multi`,
  colorId: colorId => `${ADMIN_PREFIX}/color/cards/${colorId}`,
  materials: `${ADMIN_PREFIX}/color/materials`,
  materialId: materialId => `${ADMIN_PREFIX}/color/materials/${materialId}`,

  // 经典家具
  commodityClassic: `${ADMIN_PREFIX}/commodity-classic`,
  commodityClassicId: id => `${ADMIN_PREFIX}/commodity-classic/${id}`,
  commodityClassicCommodity: id =>
    `${ADMIN_PREFIX}/commodity-classic/${id}/commodity`,
  // 模型包
  commodityPackageCats: `${ADMIN_PREFIX}/commodity-package-cat`,
  commodityPackageCatsId: catId =>
    `${ADMIN_PREFIX}/commodity-package-cat/${catId}`,
  commodityPackage: `${ADMIN_PREFIX}/commodity-package`,
  commodityPackageId: id => `${ADMIN_PREFIX}/commodity-package/${id}`,
  commodityPackageIdOnline: id =>
    `${ADMIN_PREFIX}/commodity-package/${id}/switch-online`,
  commodityPackageCommodity: id =>
    `${ADMIN_PREFIX}/commodity-package/${id}/commodity`,

  // 返佣
  commodityListOrder: `${ADMIN_PREFIX}/commodity-list-order`,
  commodityListOrderUser: `${ADMIN_PREFIX}/commodity-list-order/user`,
  commodityListOrderEdit: id => `${ADMIN_PREFIX}/commodity-list-order/${id}`,
  commodityId: id => `${ADMIN_PREFIX}/commodity-list-order/commodity/${id}`,

  // tips
  suTips: `${ADMIN_PREFIX}/su-tips`,
  suTipsEdit: id => `${ADMIN_PREFIX}/su-tips/${id}'`,

  // 打卡送会员
  userClock: `${ADMIN_PREFIX}/platform/user-clock`,

  // team
  teams: `${ADMIN_PREFIX}/teams`,
  teamId: id => `${ADMIN_PREFIX}/teams/${id}`,
  teamUser: id => `${ADMIN_PREFIX}/team/${id}/users`,
  teamUserId: (id, userId) => `${ADMIN_PREFIX}/team/${id}/users/${userId}`
};

export default API;
