import axios from "axios";
import cookies from "js-cookie";
import { message } from 'antd';
const axiosInstance = axios.create();
axiosInstance.defaults.timeout = 20000;

axiosInstance.interceptors.request.use(
  config => {
    const newConfig = {
      ...config,
      params: {
        ...config.params,
      },
      data: {
        ...config.data,
      }
    };
    const TOKEN = cookies.get("admin_token") || cookies.get("web_token");
    if (TOKEN) {
      newConfig["headers"]["Authorization"] = "Bearer " + TOKEN;
    }
    return newConfig;
  },
  error => Promise.reject(error)
);

const handleErrorRequest = error => {
  const { response } = error;
  const status = response ? response.status : 408;
  if (response) {
    const { data } = response;
    const msg = data.msg || "服务器发生错误，请稍后再试";
    if (status === 401) {
      message.error("登录态已过期，请重新登录 ～ ");
      cookies.remove("web_token", { path: "/", domain: process.env.REACT_APP_DOMAIN});
      cookies.remove("admin_token", { path: "/", domain: process.env.REACT_APP_DOMAIN});
      window.location.href = "/";
    } else if (status === 403) {
      message.error("权限不够，请联系管理员");
    } else if (status === 404) {
      message.error("不存在路由，请联系管理员");
    }else {
      message.warning("请联系管理员:" + msg);
    }
  } else {
    message.warning("请联系管理员");
  }
};

const successRes = res => {
  switch (true) {
    case res.data.error_code === 0:
      return res.data.data;
    case res.data.error_code === 404:
      return Promise.reject(res.data);
    default:
      message.error(res.data.msg);
      return Promise.reject(res.data);
  }
};

const errorRes = error => {
  handleErrorRequest(error);
  return Promise.reject(error);
};

axiosInstance.interceptors.response.use(successRes, errorRes);

const AXIOSINSTANCE = {
  post(url = "", data = {}, config = {}) {
    return axiosInstance.post(url, data, config);
  },
  put(url = "", data = {}, config = {}) {
    return axiosInstance.put(url, data, config);
  },
  get(url = "", params = {}, config = {}) {
    const OPTIONS = Object.assign({ params }, config);
    return axiosInstance.get(url, OPTIONS);
  },
  delete(url = "", params = {}, config = {}) {
    const OPTIONS = Object.assign({ params }, config);
    return axiosInstance.delete(url, OPTIONS);
  }
};

export default AXIOSINSTANCE;
