import BasicLayoutHeader from "./BasicLayoutHeader";
import { useSelector } from "react-redux";
import { permissions } from "@store/permission.slice";
import Forbidden from "@views/Common/Forbidden";
import BaiscLayoutScroll from "./BaiscLayoutScroll";

function BasicLayoutAuthenticate(props) {
  const userPermissions = useSelector(permissions);
  return (
    <div className="page-basic-body">
      <div className="page-basic-header">
        <BasicLayoutHeader breadcrumb={props.breadcrumb} />
      </div>
      <div className="page-basic-content">
        <BaiscLayoutScroll>
          {!props.permission ||
          (props.permission && userPermissions.includes(props.permission)) ? (
            props.element
          ) : (
            <Forbidden />
          )}
        </BaiscLayoutScroll>
      </div>
    </div>
  );
}

export default BasicLayoutAuthenticate;
