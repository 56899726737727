import { lazy } from "react";
import Authenticate from "@components/basic-layout/BasicLayoutAuthenticate";
import { BarcodeOutlined } from "@ant-design/icons";
const BrandAgent = lazy(() =>
  import(/* webpackChunkName: "commodity" */ "@views/Commodity/BrandAgent")
);
const Attr = lazy(() =>
  import(/* webpackChunkName: "commodity" */ "@views/Commodity/Attr")
);
const ColorMaterial = lazy(() =>
  import(/* webpackChunkName: "commodity" */ "@views/Commodity/ColorMaterial")
);
const List = lazy(() =>
  import(/* webpackChunkName: "commodity" */ "@views/Commodity/List")
);
const Sku = lazy(() =>
  import(/* webpackChunkName: "commodity" */ "@views/Commodity/Sku")
);
const Style = lazy(() =>
  import(/* webpackChunkName: "commodity" */ "@views/Commodity/Style")
);
const CommodityCat = lazy(() =>
  import(/* webpackChunkName: "commodity" */ "@views/Commodity/CommodityCat")
);
const Package = lazy(() =>
  import(/* webpackChunkName: "commodity" */ "@views/Commodity/Package")
);
const Classic = lazy(() =>
  import(/* webpackChunkName: "commodity" */ "@views/Commodity/Classic")
);
const Series = lazy(() =>
  import(/* webpackChunkName: "commodity" */ "@views/Commodity/Series")
);
const SkuRelation = lazy(() =>
  import(/* webpackChunkName: "commodity" */ "@views/Commodity/SkuRelation")
);
const SkuCreate = lazy(() =>
  import(/* webpackChunkName: "commodity" */ "@views/Commodity/SkuCreate")
);
const SkuMultiCreate = lazy(() =>
  import(/* webpackChunkName: "commodity" */ "@views/Commodity/SkuMultiCreate")
);
const CommodityCreate = lazy(() =>
  import(/* webpackChunkName: "commodity" */ "@views/Commodity/CommodityCreate")
);
const CommodityCatEdit = lazy(() =>
  import(
    /* webpackChunkName: "commodity" */ "@views/Commodity/CommodityCatEdit"
  )
);
const StyleCreate = lazy(() =>
  import(/* webpackChunkName: "commodity" */ "@views/Commodity/StyleCreate")
);
const StyleEdit = lazy(() =>
  import(/* webpackChunkName: "commodity" */ "@views/Commodity/StyleEdit")
);
const StyleCatEdit = lazy(() =>
  import(/* webpackChunkName: "commodity" */ "@views/Commodity/StyleCatEdit")
);

const commodityRoutes = [
  {
    key: "commodity",
    nav: {
      title: "商品库",
      icon: <BarcodeOutlined />
    },
    children: [
      {
        path: "/commodity/attr",
        permission: "commodity_attrs-list",
        nav: {
          title: "属性管理"
        },
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "商品库"
              },
              {
                name: "属性管理"
              }
            ]}
            title="属性管理"
            permission="commodity_attrs-list"
            element={<Attr />}
          />
        )
      },
      {
        path: "/commodity/color_material",
        permission: "commodity_attrs-list",
        nav: {
          title: "属性-颜色材料管理"
        },
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "商品库"
              },
              {
                name: "颜色材料管理"
              }
            ]}
            title="颜色材料管理"
            permission="commodity_attrs-list"
            element={<ColorMaterial />}
          />
        )
      },
      {
        path: "/commodity/brand_agent",
        permission: "commodity_brands-list",
        nav: {
          title: "品牌代理商管理"
        },
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "商品库"
              },
              {
                name: "品牌代理商管理"
              }
            ]}
            title="品牌代理商管理"
            permission="commodity_brands-list"
            element={<BrandAgent />}
          />
        )
      },
      {
        path: "/commodity/cat",
        permission: "commodity_cats-list",
        nav: {
          title: "商品类目"
        },
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "商品库"
              },
              {
                name: "商品类目"
              }
            ]}
            title="商品类目"
            permission="commodity_cats-list"
            element={<CommodityCat />}
          />
        )
      },
      {
        path: "/commodity/cat/edit",
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "商品库"
              },
              {
                name: "商品类目"
              },
              {
                name: "编辑"
              }
            ]}
            title="商品类目 - 编辑"
            permission="commodity_cats-edit"
            element={<CommodityCatEdit />}
          />
        )
      },
      {
        path: "/commodity/list",
        permission: "commodities-list",
        nav: {
          title: "商品管理"
        },
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "商品库"
              },
              {
                name: "商品管理"
              }
            ]}
            title="商品管理"
            permission="commodities-list"
            element={<List />}
          />
        )
      },
      {
        path: "/commodity/sku",
        nav: {
          title: "素材管理"
        },
        permission: "commodity_skus-list",
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "商品库"
              },
              {
                name: "素材管理"
              }
            ]}
            title="素材管理"
            permission="commodity_skus-list"
            element={<Sku />}
          />
        )
      },
      {
        path: "/commodity/sku/create",
        permission: "commodity_skus-add",
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "商品库"
              },
              {
                name: "素材管理",
                path: "/commodity/sku"
              },
              {
                name: "创建素材"
              }
            ]}
            title="创建素材"
            permission="commodity_skus-add"
            element={<SkuCreate />}
          />
        )
      },
      {
        path: "/commodity/sku/multi-create",
        permission: "commodity_skus-add",
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "商品库"
              },
              {
                name: "素材管理",
                path: "/commodity/sku"
              },
              {
                name: "创建素材"
              }
            ]}
            title="批量创建素材"
            permission="commodity_skus-add"
            element={<SkuMultiCreate />}
          />
        )
      },
      {
        path: "/commodity/create",
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "商品库"
              },
              {
                name: "商品管理",
                path: "/commodity/list"
              },
              {
                name: "创建商品"
              }
            ]}
            title="创建商品"
            permission="commodities-add"
            element={<CommodityCreate />}
          />
        )
      },
      {
        path: "/commodity/:id/sku",
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "商品库"
              },
              {
                name: "素材关联"
              }
            ]}
            title="素材关联"
            permission="commodities-detail"
            element={<SkuRelation />}
          />
        )
      },
      {
        path: "/commodity/series",
        nav: {
          title: "系列管理"
        },
        permission: "commodity_series-list",
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "商品库"
              },
              {
                name: "系列管理"
              }
            ]}
            title="系列管理"
            permission="commodity_series-list"
            element={<Series />}
          />
        )
      },
      {
        path: "/commodity/style",
        nav: {
          title: "风格管理"
        },
        permission: "commodity_style-list",
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "商品库"
              },
              {
                name: "风格管理"
              }
            ]}
            title="风格管理"
            permission="commodity_style-list"
            element={<Style />}
          />
        )
      },
      {
        path: "/commodity/style/cat/edit",
        permission: "commodity_style-edit",
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "商品库"
              },
              {
                name: "风格管理",
                path: "/commodity/style"
              },
              {
                name: "编辑类目"
              }
            ]}
            title="编辑类目"
            permission="commodity_style-edit"
            element={<StyleCatEdit />}
          />
        )
      },
      {
        path: "/commodity/style/create",
        permission: "commodity_style-add",
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "商品库"
              },
              {
                name: "风格管理",
                path: "/commodity/style"
              },
              {
                name: "创建风格 "
              }
            ]}
            title="创建风格"
            permission="commodity_style-add"
            element={<StyleCreate />}
          />
        )
      },
      {
        path: "/commodity/style/:id",
        permission: "commodity_style-edit",
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "商品库"
              },
              {
                name: "风格管理",
                path: "/commodity/style"
              },
              {
                name: "编辑风格 "
              }
            ]}
            title="创建风格"
            permission="commodity_style-edit"
            element={<StyleEdit />}
          />
        )
      },
      {
        path: "/commodity/package",
        nav: {
          title: "合集包管理"
        },
        permission: "commodity_package-list",
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "商品库"
              },
              {
                name: "合集包管理"
              }
            ]}
            title="合集包管理"
            permission="commodity_package-list"
            element={<Package />}
          />
        )
      },
      {
        path: "/commodity/classic",
        nav: {
          title: "经典家具管理"
        },
        permission: "commodity_classic-list",
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "商品库"
              },
              {
                name: "经典家具管理"
              }
            ]}
            title="经典家具管理"
            permission="commodity_classic-list"
            element={<Classic />}
          />
        )
      }
    ]
  }
];

export default commodityRoutes;
