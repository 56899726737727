import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Scrollbars } from 'react-custom-scrollbars';
import { MenuDatas } from "@src/router/routes";
import { Menu } from 'antd';
import { ImportOutlined, RotateRightOutlined } from '@ant-design/icons';
import { useSelector } from "react-redux";
import { permissions } from "@store/permission.slice";
import HOMEPLAN_LOGO from "@assets/images/layout/header/homeplan-logo.svg";

const filterPermissionRoutes = (routes, permissions) => {
  const filterRoutes = [];
  routes.forEach(data => {
    const route = { ...data };
    const notPermission = !route.permission;
    const hasPermission =
      !notPermission && permissions.includes(route.permission);
    const passPermission = notPermission || hasPermission;
    if (route.children) {
      route.children = filterPermissionRoutes(
        data.children,
        permissions
      );
    }
    if (passPermission) {
      filterRoutes.push(route);
    }
  });
  return filterRoutes.filter(data => {
    return data.children?.length || data.path
  });
}

function BasicLayoutHeaderNav(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [inlineCollapsed, setInlineCollapsed] = useState(false);
  const userPermissions = useSelector(permissions);

  const handleNavigateTo = e => navigate(e.key);
  const handleToggleCollapsed = () => setInlineCollapsed(!inlineCollapsed);
  const defaultOpenSubMenu = MenuDatas.find(data => {
    return data.children 
      && data.children.length > 0
      && location.pathname.indexOf(data.key) === 1;
  });

  const filterMenuDatas = filterPermissionRoutes(MenuDatas, userPermissions);

  const defaultOpenKey = defaultOpenSubMenu 
    ? defaultOpenSubMenu.key 
    : null;
  
  const menuItems = filterMenuDatas.map(data => {
    const children = (data.children || []).map((child) => {
      return {
        label: child.title,
        key:child.path
      }
    })
    return {
      label: data.title,
      icon: data.icon,
      key: data.path,
      children: children.length ? children : null
    }
  })

  return (
    <>
      <Scrollbars style={{width: inlineCollapsed ? 80 : 228}} autoHide>
        <div className="header-logo-container">
          { !inlineCollapsed && <img className="header-logo-element" alt="斗西家计划" src={HOMEPLAN_LOGO} />}
          <div className="header-logo-collapse-btn" onClick={handleToggleCollapsed}>
            {inlineCollapsed ? <RotateRightOutlined /> : <ImportOutlined />}
          </div>
        </div>
        <Menu
          onClick={handleNavigateTo}
          style={{ width: inlineCollapsed ? 80 : 228 }}
          inlineCollapsed={inlineCollapsed}
          selectedKeys={[location.pathname]}
          defaultOpenKeys={[defaultOpenKey]}
          mode="inline"
          items={menuItems}
        >
        </Menu>
      </Scrollbars>
    </>
  );
}

export default BasicLayoutHeaderNav;