import { lazy } from "react";
import Authenticate from "@components/basic-layout/BasicLayoutAuthenticate";
import { IssuesCloseOutlined } from "@ant-design/icons";
const FeedbackSpeeduck = lazy(() =>
  import(/* webpackChunkName: "speeduck" */ "@views/Speeduck/FeedbackSpeeduck")
);
const FeedbackModel = lazy(() =>
  import(/* webpackChunkName: "speeduck" */ "@views/Speeduck/FeedbackModel")
);
const Version = lazy(() =>
  import(/* webpackChunkName: "speeduck" */ "@views/Speeduck/Version")
);
const UserDetail = lazy(() =>
  import(/* webpackChunkName: "speeduck" */ "@views/Speeduck/UserDetail")
);
const Tips = lazy(() =>
  import(/* webpackChunkName: "speeduck" */ "@views/Speeduck/Tips")
);

const speeduckRoutes = [
  {
    key: "/okducky",
    nav: {
      title: "可搭鸭管理",
      icon: <IssuesCloseOutlined />
    },
    children: [
      {
        path: "/okducky/list",
        permission: "version-list",
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "可搭鸭"
              },
              {
                name: "版本列表"
              }
            ]}
            title="版本列表"
            permission="version-list"
            element={<Version />}
          />
        ),
        nav: {
          title: "版本列表"
        }
      },
      {
        path: "/okducky/user",
        permission: "platform-users-index",
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "可搭鸭"
              },
              {
                name: "用户信息"
              }
            ]}
            title="用户信息"
            permission="platform-users-index"
            element={<UserDetail />}
          />
        ),
        nav: {
          title: "用户信息"
        }
      },
      {
        path: "/okducky/feedback",
        permission: "su_speeduck_feedback-list",
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "可搭鸭管理"
              },
              {
                name: "满意度管理"
              }
            ]}
            title="满意度管理"
            permission="su_speeduck_feedback-list"
            element={<FeedbackSpeeduck />}
          />
        ),
        nav: {
          title: "满意度管理"
        }
      },
      {
        path: "/okducky/model_feedback",
        permission: "su_model_feedback-list",
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "可搭鸭管理"
              },
              {
                name: "错误反馈管理"
              }
            ]}
            title="错误反馈管理"
            permission="su_model_feedback-list"
            element={<FeedbackModel />}
          />
        ),
        nav: {
          title: "错误反馈管理"
        }
      },
      {
        path: "/okducky/tips",
        nav: {
          title: "Tips内容管理"
        },
        permission: "su-tips-list",
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "商品库"
              },
              {
                name: "Tips内容管理"
              }
            ]}
            title="Tips内容管理"
            permission="su-tips-list"
            element={<Tips />}
          />
        )
      }
    ]
  }
];

export default speeduckRoutes;
