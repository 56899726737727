import * as React from "react";
import BasicLayout from "@components/basic-layout";
import Authenticate from "@components/basic-layout/BasicLayoutAuthenticate";
import { AppstoreOutlined } from "@ant-design/icons";
import permissionRoutes from "./permission-routes";
import commodityRoutes from "./commodity-routes";
import commodityCommissionRoutes from "./commodity-commission-routes";
import colorRoutes from "./color-routes";
import daylabRoutes from "./daylab-routes";
import userRoutes from "./user-routes";
import speeduckRoutes from "./speeduck-routes";
import teamRoutes from "./team-routes";
import Home from "@views/Home";
import NotFound from "@views/Common/NotFound";

const routes = [
  {
    path: "/",
    element: <BasicLayout />,
    children: [
      {
        path: "/",
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "概览"
              }
            ]}
            element={<Home />}
          />
        ),
        nav: {
          title: "概览",
          icon: <AppstoreOutlined />
        }
      },
      ...commodityRoutes,
      ...commodityCommissionRoutes,
      ...colorRoutes,
      ...speeduckRoutes,
      ...daylabRoutes,
      ...userRoutes,
      ...permissionRoutes,
      ...teamRoutes
    ]
  },
  {
    path: "*",
    element: <NotFound />
  }
];

const filterRoutes = routes => {
  const result = [];
  routes.forEach(data => {
    if (data.nav) {
      let item = {
        path: data.path,
        title: data.nav.title,
        icon: data.nav.icon,
        permission: data.permission,
        key: data.key
      };
      if (data.children) {
        item.children = filterRoutes(data.children);
      }
      result.push(item);
    } else if (data.children) {
      filterRoutes(data.children).forEach(item => {
        result.push(item);
      });
    }
  });
  return result;
};

export const MenuDatas = filterRoutes(routes);
export default routes;
