import request from "./../request/axios.js";
import API from "./../request/api.js";

const userService = {
  getUserInfo() {
    return request.get(API.userInfo);
  },
  myPermissions() {
    return request.get(API.userPermissions);
  },
  list(params) {
    return request.get(API.user, params);
  },
  userAdd(params) {
    return request.post(API.user, params);
  },
  userShow(id) {
    return request.get(API.userId(id));
  },
  userUpdate(id, params) {
    return request.put(API.userId(id), params);
  },
  userClock(params) {
    return request.get(API.userClock, params);
  }
};

export default userService;
