import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { user, delUserInfo } from "@store/user.slice";
import BasicLayoutHeaderAvatar from "./BasicLayoutHeaderAvatar";
import cookies from "js-cookie";
import { Breadcrumb } from "antd";
import { HomeOutlined } from '@ant-design/icons';

function BasicLayoutHeader(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector(user);

  const handleLogout = () => {
    cookies.remove("web_token", 
      { path: "/", domain: process.env.REACT_APP_DOMAIN});
      cookies.remove("admin_token", 
      { path: "/", domain: process.env.REACT_APP_DOMAIN});
    dispatch(delUserInfo());
    navigate("/");
  }

  return (
    <header className="page-header">
      <div className="header-content">
        <div className="header-hd">
          <div className="header-breadcrumb">
            <Breadcrumb>
                <Breadcrumb.Item>
                  <HomeOutlined />
                </Breadcrumb.Item>
                {(props.breadcrumb || []).map((item,index) => {
                    return <Breadcrumb.Item key={index}>
                      {item.path
                        ? <Link to={item.path}>{item.name}</Link>
                        : item.name }
                    </Breadcrumb.Item>
                })}
              </Breadcrumb>
          </div>
        </div>
        <div className="header-ft">
          {(
            <BasicLayoutHeaderAvatar 
              userInfo={userInfo} 
              clickMenu={() => {}}
              logout={handleLogout}
            />
          )}
        </div>
      </div>
    </header>
  );
}

export default BasicLayoutHeader;