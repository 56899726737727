import { lazy } from "react";
import Authenticate from "@components/basic-layout/BasicLayoutAuthenticate";
import { SettingOutlined } from "@ant-design/icons";
const Manager = lazy(() =>
  import(/* webpackChunkName: "permission" */ "@views/Permission/Manager")
);
const Role = lazy(() =>
  import(/* webpackChunkName: "permission" */ "@views/Permission/Role")
);
const RoleDetail = lazy(() =>
  import(/* webpackChunkName: "permission" */ "@views/Permission/RoleDetail")
);
const RoleCreate = lazy(() =>
  import(/* webpackChunkName: "permission" */ "@views/Permission/RoleCreate")
);
const permissionRoutes = [
  {
    key: "permission",
    nav: {
      title: "权限管理",
      icon: <SettingOutlined />
    },
    children: [
      {
        path: "/permission/manager",
        permission: "administrators-list",
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "权限管理"
              },
              {
                name: "管理员"
              }
            ]}
            title="管理员"
            permission="administrators-list"
            element={<Manager />}
          />
        ),
        nav: {
          title: "管理员"
        }
      },
      {
        path: "/permission/role",
        permission: "roles-list",
        nav: {
          title: "角色"
        },
        children: [
          {
            path: "/permission/role",
            element: (
              <Authenticate
                breadcrumb={[
                  {
                    name: "权限管理"
                  },
                  {
                    name: "角色"
                  }
                ]}
                title="角色"
                permission="roles-list"
                element={<Role />}
              />
            )
          },
          {
            path: "/permission/role/create",
            element: (
              <Authenticate
                breadcrumb={[
                  {
                    name: "权限管理"
                  },
                  {
                    name: "角色",
                    path: "/permission/role"
                  },
                  {
                    name: "创建角色"
                  }
                ]}
                title="创建角色"
                permission="roles-add"
                element={<RoleCreate />}
              />
            )
          },
          {
            path: "/permission/role/:id",
            element: (
              <Authenticate
                breadcrumb={[
                  {
                    name: "权限管理"
                  },
                  {
                    name: "角色",
                    path: "/permission/role"
                  },
                  {
                    name: "角色详情"
                  }
                ]}
                title="角色详情"
                permission="roles-detail"
                element={<RoleDetail />}
              />
            )
          }
        ]
      }
    ]
  }
];

export default permissionRoutes;
