import { lazy } from "react";
import Authenticate from "@components/basic-layout/BasicLayoutAuthenticate";
import { SoundOutlined } from "@ant-design/icons";
const WorkLocation = lazy(() =>
  import(/* webpackChunkName: "daylab" */ "@views/Daylab/WorkLocation")
);
const WorkList = lazy(() =>
  import(/* webpackChunkName: "daylab" */ "@views/Daylab/WorkList")
);
const AwardList = lazy(() =>
  import(/* webpackChunkName: "daylab" */ "@views/Daylab/AwardList")
);
const TeamMemberList = lazy(() =>
  import(/* webpackChunkName: "daylab" */ "@views/Daylab/TeamMemberList")
);
const AwardDetail = lazy(() =>
  import(/* webpackChunkName: "daylab" */ "@views/Daylab/AwardDetail")
);
const WorkDetail = lazy(() =>
  import(/* webpackChunkName: "daylab" */ "@views/Daylab/WorkDetail")
);
const TeamMemberDetail = lazy(() =>
  import(/* webpackChunkName: "daylab" */ "@views/Daylab/TeamMemberDetail")
);

const daylabRoutes = [
  {
    key: "daylab",
    nav: {
      title: "Daylab",
      icon: <SoundOutlined />
    },
    children: [
      {
        path: "/daylab/work-location",
        permission: "daylab_work_locations-list",
        nav: {
          title: "地点"
        },
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "Daylab"
              },
              {
                name: "地点"
              }
            ]}
            title="训练营"
            permission="daylab_work_locations-list"
            element={<WorkLocation />}
          />
        )
      },
      {
        path: "/daylab/work",
        permission: "daylab_works-list",
        nav: {
          title: "作品"
        },
        children: [
          {
            path: "/daylab/work",
            permission: "daylab_works-list",
            element: (
              <Authenticate
                breadcrumb={[
                  {
                    name: "Daylab"
                  },
                  {
                    name: "作品"
                  }
                ]}
                title="作品"
                permission="daylab_works-list"
                element={<WorkList />}
              />
            )
          },
          {
            path: "/daylab/work/create",
            permission: "daylab_works-add",
            element: (
              <Authenticate
                breadcrumb={[
                  {
                    name: "Daylab"
                  },
                  {
                    name: "作品",
                    path: "/daylab/work"
                  },
                  {
                    name: "添加"
                  }
                ]}
                title="作品-添加"
                permission="daylab_works-add"
                element={<WorkDetail />}
              />
            )
          },
          {
            path: "/daylab/work/:id",
            permission: "daylab_works-detail",
            element: (
              <Authenticate
                breadcrumb={[
                  {
                    name: "Daylab"
                  },
                  {
                    name: "作品",
                    path: "/daylab/work"
                  },
                  {
                    name: "详情"
                  }
                ]}
                title="作品-详情"
                permission="daylab_works-detail"
                element={<WorkDetail />}
              />
            )
          }
        ]
      },
      {
        path: "/daylab/award",
        permission: "daylab_awards-list",
        nav: {
          title: "荣誉报道"
        },
        children: [
          {
            path: "/daylab/award",
            permission: "daylab_awards-list",
            element: (
              <Authenticate
                breadcrumb={[
                  {
                    name: "Daylab"
                  },
                  {
                    name: "荣誉报道"
                  }
                ]}
                title="荣誉报道"
                permission="daylab_awards-list"
                element={<AwardList />}
              />
            )
          },
          {
            path: "/daylab/award/create",
            permission: "daylab_awards-add",
            element: (
              <Authenticate
                breadcrumb={[
                  {
                    name: "Daylab"
                  },
                  {
                    name: "荣誉报道",
                    path: "/daylab/award"
                  },
                  {
                    name: "添加"
                  }
                ]}
                title="荣誉报道-添加"
                permission="daylab_awards-add"
                element={<AwardDetail />}
              />
            )
          },
          {
            path: "/daylab/award/:id",
            permission: "daylab_awards-detail",
            element: (
              <Authenticate
                breadcrumb={[
                  {
                    name: "Daylab"
                  },
                  {
                    name: "荣誉报道",
                    path: "/daylab/award"
                  },
                  {
                    name: "编辑"
                  }
                ]}
                title="荣誉报道-编辑"
                permission="daylab_awards-detail"
                element={<AwardDetail />}
              />
            )
          }
        ]
      },
      {
        path: "/daylab/team-member",
        permission: "daylab_team_members-list",
        nav: {
          title: "团队"
        },
        children: [
          {
            path: "/daylab/team-member",
            permission: "daylab_team_members-list",
            element: (
              <Authenticate
                breadcrumb={[
                  {
                    name: "Daylab"
                  },
                  {
                    name: "团队"
                  }
                ]}
                title="团队"
                permission="daylab_team_members-list"
                element={<TeamMemberList />}
              />
            )
          },
          {
            path: "/daylab/team-member/create",
            permission: "daylab_team_members-add",
            element: (
              <Authenticate
                breadcrumb={[
                  {
                    name: "Daylab"
                  },
                  {
                    name: "团队",
                    path: "/daylab/team-member"
                  },
                  {
                    name: "添加"
                  }
                ]}
                title="团队-添加"
                permission="daylab_team_members-add"
                element={<TeamMemberDetail />}
              />
            )
          },
          {
            path: "/daylab/team-member/:id",
            permission: "daylab_team_members-detail",
            element: (
              <Authenticate
                breadcrumb={[
                  {
                    name: "Daylab"
                  },
                  {
                    name: "团队",
                    path: "/daylab/team-member"
                  },
                  {
                    name: "编辑"
                  }
                ]}
                title="团队-编辑"
                permission="daylab_team_members-detail"
                element={<TeamMemberDetail />}
              />
            )
          }
        ]
      }
    ]
  }
];

export default daylabRoutes;
