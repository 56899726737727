import { lazy } from "react";
import Authenticate from "@components/basic-layout/BasicLayoutAuthenticate";
import { FormatPainterOutlined } from "@ant-design/icons";
const ColorCard = lazy(() =>
  import(/* webpackChunkName: "color" */ "@views/Color/ColorCard")
);
const ColorMaterial = lazy(() =>
  import(/* webpackChunkName: "color" */ "@views/Color/ColorMaterial")
);
const ColorCatEdit = lazy(() =>
  import(/* webpackChunkName: "color" */ "@views/Color/ColorCatEdit")
);

const colorRoutes = [
  {
    key: "color",
    nav: {
      title: "色卡",
      icon: <FormatPainterOutlined />
    },
    children: [
      {
        path: "/color/material",
        nav: {
          title: "材质管理"
        },
        permission: "color_material-list",
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "色卡"
              },
              {
                name: "材质管理"
              }
            ]}
            title="材质管理"
            permission="color_material-list"
            element={<ColorMaterial />}
          />
        )
      },
      {
        path: "/color/card",
        nav: {
          title: "颜色管理"
        },
        permission: "color_card-list",
        element: (
          <Authenticate
            breadcrumb={[
              {
                name: "色卡"
              },
              {
                name: "颜色管理"
              }
            ]}
            title="颜色管理"
            permission="color_card-list"
            element={<ColorCard />}
          />
        )
      }
    ]
  },
  {
    path: "/color/card/cat/edit",
    element: (
      <Authenticate
        breadcrumb={[
          {
            name: "色卡"
          },
          {
            name: "颜色管理"
          },
          {
            name: "编辑"
          }
        ]}
        title="颜色类目 - 编辑"
        permission="color_cat-edit"
        element={<ColorCatEdit />}
      />
    )
  }
];

export default colorRoutes;
