import BindPhoneModal from "@components/basic-common/BindPhoneModal";

function UnAuthenticated() {

  return (   
    <div style={{
      paddingTop: "120px"
    }}> 
      <BindPhoneModal />
    </div>
  );
}

export default UnAuthenticated;