import { Outlet } from "react-router-dom";
import BasicLayoutHeaderNav from "./BasicLayoutHeaderNav";
import "./style";

function BasicLayout() {
  return (
    <div className="page-basic-layout">
      <div className="page-basic-nav">
        <BasicLayoutHeaderNav/>
      </div>
      <Outlet />
    </div>
  );
}

export default BasicLayout;