import { createSlice, createSelector } from "@reduxjs/toolkit";

export const PERMISSION_FEATURE_KEY = "PERMISSION";
const { reducer: permissionReducer, actions } = createSlice({
  name: PERMISSION_FEATURE_KEY,
  initialState: [],
  reducers: {
    setPermissions: (state, action) => {
      return action.payload;
    },
  },
});

export const permissions = createSelector(
  (state) => state[PERMISSION_FEATURE_KEY],
  (permissions) => permissions
);

export const { setPermissions } = actions;
export default permissionReducer;
